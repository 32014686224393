import React, { useState, useEffect } from 'react';
import Datatables from '../../components/datatables/datatable';
import Layout from '../../views/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Services from '../../services/global.service';
import DeleteModal from '../../components/modals/delete';



function Annonces() {
  const [annonces, setAnnonces] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [action, setAction] = useState('');
  const [idToDeleted, setIdToDelete] = useState('');

  //open the modal and save the id user to delete
  function handleDelete(id){
    console.log("id",id)
    setIdToDelete(id);
    setIsOpenDelete(true);
   
  }
   

  const columns = [
    {
      selector: (row) =>row.reference,
     // selector: 'email',
      name: 'ID annonce',
      sortable: true,
    //   grow: 2,
      //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
    
    {
      selector: (row) =>row.name,
     // selector: 'email',
      name: "Nom de l'annonce",
      sortable: true,
    //   grow: 2,
      //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
    {
      selector: (row) =>`${row.price} €`,
      name: 'Prix',
      sortable: true,
      //grow: 1,
      //sortIcon: <img src={filterIcon} alt="Sort" />,
  },
    {
        selector: (row) =>row.dept,
        name: 'Département de réception',
        sortable: true,
        //grow: 1,
        //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
    {
        selector: (row) =>new Date(row.receptionDate).toLocaleDateString('fr-FR'),
        name: 'Date max souhaitée',
        sortable: true,
        //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
  
    {
        selector: (row) =>row.status,
        name: "Statut de l'annonce",
        sortable: true,
        cell: (row) => (
              <span >
             {
      row.status === "published"
        ? "Publiée"
        : row.status === "scheduled"
        ? "Programmée"
        : row.status === "inProgress"
        ? "En cours"
        : row.status === "completed"
        ? "Terminée"
        : row.status === "suspended"
        ? "Suspendue"
        : row.status === "archived"
        ? "Archivée"
        : row.status === "deleted"
        ? "Supprimée"
        : ""
    }
              </span>
            ),
       // width:'20%'
       // grow: 1,
        //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
    
    
    {
      name: "",
      // grow: 1,
      // width:'15%',
      cell: (row) =>
      {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">

            {(row?.status!=='archived' && row?.status!=="deleted") ?<button
              type="button"
              className='btn-secondary-ko'
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
                setAction('archived')
              }}
             
            >
              <FontAwesomeIcon
                    icon={faTrash}
                   
                />
            </button>
      :''}
          </div>);
        
      }
      ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  function getAnnonces(){
    console.log('here')
    /*get user with role=== role_admin**/
    Services.getAllAdmin('annonce/getAllAdmin',localStorage.getItem('token'))
    .then((response)=>
    {
     console.log('res', response);
    setAnnonces(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  const hideModal = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  const DeleteAnnonce = () => {
    const data={
      status:action
    }
    Services.patch(data,idToDeleted,'annonce/status')
    .then((res)=>{
      console.log(res)
      getAnnonces();
    })
    .catch((err)=>{
      console.log(err)
    })
  };

  useEffect(() => {
  getAnnonces();
  }, [idToDeleted,isOpenDelete]);

  return (
    <>

      <Layout>
      {isOpenDelete ? (
        <DeleteModal
          isOpenDelete={isOpenDelete}
          setIsOpenDelete={setIsOpenDelete}
          hideModal={hideModal}
          Function={DeleteAnnonce}
          title="Souhaitez-vous supprimer/archiver cette annonce ?"
        />
      ) : (
        ""
      )}
      <div className="col  bg-fond container-k">

       <div className="m-vh-100  p-40">
       <div className="container">
       
       <div className="d-flex justify-content-between"><h1 className="d-title">Liste Annonces    <span className="border-title"></span> 
       </h1> </div>
       
        <div className="space-72"></div>
      
        
        </div>  
        <div className="koper">
         {annonces && 
         <Datatables
          data={annonces}
          columns={columns}
          title={'Liste des utilisateurs'}
          path={'/annonces'}
          selectable={false}
          onRowSelect
          noDataText={'Aucune annonce trouvé'}
          tabs={[
            // { value: "actif", name: "Utilisateurs Actifs" },
            // { value: "banned", name: "Utilisateurs bannés" },
            // { value: "deleted", name: "Utilisateurs supprimés" },
            // { value: "all", name: "Tous" },
          ]}
         />
          } 
        </div>
        </div> 
        </div>
       </Layout>
    
    </>
  );
}

export default Annonces;

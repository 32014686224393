import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from 'react';

export default function Datatables({ data, columns, path,tabs,noDataText ,setTabs}) {
  console.log('data', data)
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabs.length>0 ? tabs[0].value : 'all');



  const customStyles = {
    table: {
        style: {
          backgroundColor: '#FCFCFC',
          fontSize:"16px",
          //borderRadius:"10px",
         
        },
      },
    headCells: {
      style: {
        backgroundColor: '#F4F3F3',
        // border: "0.5px solid #FCFCFC",
        // borderRadius:"10px 10px 0 0",
        // borderBottomStyle:"hidden",
        fontSize:"16px",
        fontFamily:'Lato-Bold',
        fontWeight:'600',
      //  marginLeft:'10px',

      }
    },
    rows: {
        style: {
            cursor: 'pointer',
            backgroundColor:"#fff",
           // borderRadius:"20px",
           // margin:"10px",
           // width:'98%',
            // boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.1)",
            //  borderBottomStyle:"#DEDEDE",
            //  borderBottomWidth:"90%",
            fontSize:"16px",
            fontFamily:'Lato-Light',
            minHeight:'64px'
        },
      },
     
    
  }
 
const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tous',

};
const handleRowClick = (row) => {
    // Redirect to the detail event page using the row id
    window.location.href = `${path}/${row._id}`;
  };
  const filterData = () => {
    let tempFilteredData = data;
  console.log('current tab', currentTab)    
  if (!Array.isArray(tempFilteredData)) {
      // Handle the case when data is not an array (or undefined)
      tempFilteredData = [];
    }
  
    if (currentTab !== 'all') {
//console.log(item.status)
      tempFilteredData = tempFilteredData?.filter(item => item?.status === currentTab);
    }
  
    if (filterText) {
      tempFilteredData = tempFilteredData?.filter(
        item =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      );
    }
  
    setFilteredItems(tempFilteredData);
  };
  

  useEffect(() => {
    filterData();
  }, [currentTab, data, filterText]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    return (
      <>
        <div className='col-md-12 ' >
          <input
            className='form-control'
            id="search"
            type="text"
            placeholder="Recherche"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className="col-md-12 tabs-container mt-38">
        {tabs?.map(el=>{
          return (   
             <div   key={el.value} className={`tab ${currentTab === el.value ? 'active' : ''}`} onClick={() =>{ setCurrentTab(el?.value)
              setTabs(el?.value)  }}>
          <a>{el?.name}</a>
        </div>)
        })}
            </div>
    
      </>
    );
  }, [filterText,data, currentTab]);

  const handleSelectedRowsChange = (selectedRows) => {
    const selectedIndexes = selectedRows.selectedRows.map((row) => row._id);
    setSelectedRows(selectedIndexes);
  };

  return (
    <>
      <div className='col-md-12 mt-24'>
       {data && <DataTable
          columns={columns}
          data={filteredItems}
          className="datatable-all mt-24"
          pagination
          paginationComponentOptions={paginationComponentOptions}
          customStyles={customStyles}
          onRowClicked={(row) => {  
            if(path!==''){
               handleRowClick(row); 
            }
           
           }}
          subHeader
          subHeaderComponent={subHeaderComponent}
          onSelectedRowsChange={handleSelectedRowsChange}
          noDataComponent={<div className="p-3">{noDataText}</div>}
        />}
      </div>
    </>
  );
}

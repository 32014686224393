import React, { useState, useEffect } from 'react';
import Datatables from '../../components/datatables/datatable';
import Layout from '../../views/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash , faBan, faUserMinus} from '@fortawesome/free-solid-svg-icons';
import Services from '../../services/global.service';
import DeleteModal from '../../components/modals/delete';



function Users() {
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState('');
  const [actionText, setActionText] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [idToDeleted, setIdToDelete] = useState('');
  const [tabs, setTabs] = useState('');


  //open the modal and save the id user to delete
  function handleDelete(id){
    console.log("id",id)
    setIdToDelete(id);
    setIsOpenDelete(true);
   
   
  }
  function handleDate(date){
    const dateDans3Ans = new Date(date);
    dateDans3Ans.setFullYear(dateDans3Ans.getFullYear() + 3);
    const differenceEnMilliseconds = dateDans3Ans.getTime() - Date.now();
    const differenceEnJours = Math.ceil(differenceEnMilliseconds / (1000 * 60 * 60 * 24));
   return differenceEnJours
  }
   
   

  const columns = [
  
    {
        selector: (row) =>row.firstName,
        name: 'Nom',
        sortable: true,
        grow: 1,
        //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
    {
        selector: (row)=>row.lastName,
        name: 'Prénom',
        sortable: true,
        //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
    {
        selector: (row)=>row.tel,
        name: 'Téléphone',
        sortable: true,
        grow: 1,
        //sortIcon: <img src={filterIcon} alt="Sort" />,
    },
   
     
     {
      selector: (row) => tabs ==='deleted'?handleDate(row.updateAt):row.email,
     // selector: 'email',
      name: tabs ==='deleted' ? 'Jours avant suppression':'E-mail',
      sortable: true,
      grow: 2,
     
      //sortIcon: <img src={filterIcon} alt="Sort" />,
    }, 
    {
      selector: (row)=> tabs ==='deleted'? row?.updateAt : row?.createdAt,
      name: tabs ==='deleted' ?'Date suppression' :'Date de création compte',
      sortable: true,
      grow: 1,
      width:'20%',
      cell: (row) => {
        // Check if createdAt is defined and not null
       if( tabs ==='deleted'){
        if (row?.updateAt) {
          const dateObject = new Date(row?.updateAt);
          if (!isNaN(dateObject.getTime())) {
            return dateObject.toLocaleDateString();
          }
        }
        return '';
       }else{
        if (row.createdAt) {
          const dateObject = new Date(row.createdAt);
          if (!isNaN(dateObject.getTime())) {
            return dateObject.toLocaleDateString();
          }
        }
        return '';
       }
        // or any default value you prefer
      }
      //sortIcon: <img src={filterIcon} alt="Sort" />,
  },
    {
      name: "",
      // grow: 2,
      // width:'30%',
      cell: (row) =>
      {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
      {row?.status==='actif' ? 
          <><button
              type="button"
              className='btn-secondary-ko'
              title="Bloquer"
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
                setAction('banned')
                setActionText('Bloquer')

              }}
             
            >
              <FontAwesomeIcon
                    icon={faBan}
                   
                />
            </button>
            <button
              type="button"
              className='mx-1 btn-secondary-ko'
              title="Supprimer"
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
                setAction('deleted')
                setActionText('Supprimer')

              }}
             
            >
              <FontAwesomeIcon
                    icon={faTrash}
                   
                />
            </button></> : 
            <button
              type="button"
              className='mx-1 btn-secondary-ko'
              title="Débloquer"
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
                setAction('actif')
                setActionText('Débloquer')
              }}
             
            >
              <FontAwesomeIcon
                    icon={faUserMinus}
                   
                />
            </button>}
            {row?.status==='banned' ? 
          <>
            <button
              type="button"
              className='btn-secondary-ko'
              onClick={() => {
                handleDelete(row._id);
                setIdToDelete(row._id);
                setAction('deleted')
                setActionText('Supprimer')

              }}
             
            >
              <FontAwesomeIcon
                    icon={faTrash}
                   
                />
            </button></> :''}  

          </div>);
        
      }
      ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  function getUsers(){
    console.log('here')
    /*get user with role=== role_admin**/
    Services.getAll('user/getAll',localStorage.getItem('token'))
    .then((response)=>
    {
     console.log('res', response);
    setUsers(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  const hideModal = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  const Bloquer = () => {
    const data={
      status:action,
      id:idToDeleted,
      by:'admin'
    }
    
    Services.create(data,'user/updateStatus' )
    .then((res)=>{
      console.log(res)
      getUsers();
    })
    .catch((err)=>{
      console.log(err)
    })
  };

  useEffect(() => {
  getUsers();
  }, [isOpenDelete]);

  return (
    <>

      <Layout>
      {isOpenDelete ? (
        <DeleteModal
          isOpenDelete={isOpenDelete}
          setIsOpenDelete={setIsOpenDelete}
          hideModal={hideModal}
          Function={Bloquer}
          title={`Souhaitez-vous ${actionText} cette personne ?`}
        />
      ) : (
        ""
      )}
      <div className="col  bg-fond container-k">

       <div className="m-vh-100  p-40">
       <div className="container">
       
       <div className="d-flex justify-content-between"><h1 className="d-title">Liste Utilisateurs    <span className="border-title"></span> 
       </h1> </div>
       
        <div className="space-72"></div>
      
        
        </div>  
        <div className="koper">
         {users && 
         <
          Datatables
          data={users}
          columns={columns}
          title={'Liste des utilisateurs'}
          path={'users'}
          selectable={false}
          onRowSelect
          noDataText={'Aucun utilisateur trouvé'}
          tabs={[
            { value: "actif", name: "Utilisateurs Actifs" },
            { value: "banned", name: "Utilisateurs bloqués" },
            { value: "deleted", name: "Utilisateurs supprimés" },
            { value: "all", name: "Tous" },
          ]}
          setTabs={(val)=>setTabs(val)}
         />
          } 
        </div>
        </div> 
        </div>
       </Layout>
    
    </>
  );
}

export default Users;

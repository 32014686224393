import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersRectangle,faSignOut, faComments, faDashboard } from '@fortawesome/free-solid-svg-icons';
import './sidebar.css';
import logo from '../../assets/icons/logoSidebar.svg';

import { useNavigate } from 'react-router-dom';
function SideBar() {
  const navigate = useNavigate();
  const [closeSideBar, setCloseSideBar] = useState(false);
  const logOut=()=>{
    localStorage.removeItem('token');
    navigate('/login')
  }
  return (
    <div className={`col-auto col-md-2 col-xl-2 px-0 shadow-sidebar ${closeSideBar ? 'close-sidebar' : ''}`}>
      <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
        <button className="btn btn-link" onClick={() => { setCloseSideBar(!closeSideBar) }}>
          <img src={logo} alt="logo" className="logo" />
        </button>

       
        <Nav className="flex-column">
        <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
                 <FontAwesomeIcon
                    icon={faDashboard}
                    className={isActive ? 'active-icon mx-2' : 'not-active-icon mx-2'}
                />
                {!closeSideBar && <span className=" d-none d-sm-inline">Dashboard</span>}
              </>
            )}
          </NavLink>
          <NavLink to="/users" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
                 <FontAwesomeIcon
                    icon={faUsersRectangle}
                    className={isActive ? 'active-icon mx-2' : 'not-active-icon mx-2'}
                />
                {!closeSideBar && <span className=" d-none d-sm-inline">Utilisateurs</span>}
              </>
            )}
          </NavLink>
          <NavLink to="/annonces" className={({ isActive }) => (isActive ? "nav-item active" : "nav-item ")}>
            {({ isActive }) => (
              <>
                 <FontAwesomeIcon
                    icon={faComments}
                    className={isActive ? 'active-icon mx-2' : 'not-active-icon mx-2'}
                />
                {!closeSideBar && <span className=" d-none d-sm-inline">Annonces</span>}
              </>
            )}
          </NavLink>
        </Nav>

   
      </div>
      <button onClick={()=>{logOut()}} className="cta logout pl-30"> <FontAwesomeIcon icon={faSignOut} />  {!closeSideBar && <span className=" d-none d-sm-inline pl-13">Me déconnecter</span>}</button>
    </div>
  );
}

export default SideBar;

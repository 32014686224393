import { useEffect, useState } from "react";
import Layout from "../layout";
import Services from "../../services/global.service";
import { useParams } from "react-router";

const DetailsAnnonce = () => {
  const params = useParams();
  const [details, setDetails] = useState(null); // Initialize with null

  const getDetails = () => {
    Services.getOne(params.id, "annonce/get")
      .then((res) => {
        console.log(res);
        setDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDetails();
  }, [params.id]); // Update details when params.id changes

  return (
    <Layout>
      <div className="container mt-5">
        <h1>Détails Annonce</h1>
        {details && ( // Conditionally render when details is available
          <>
           
            <div className="row p-2">
            <div className="col-12 border-details m-2 p-3">
              {/*  */}
              <h5>Informations annonceur</h5>
              <div className="row">
                <div className="col-6 mt-24"> <p className=""> <strong>Prénom : </strong> {details?.user?.firstName}</p>
             <p><strong>Nom : </strong>{details?.user?.lastName}</p></div>
                <div className="col-6 mt-24"> <p><strong>E-mail : </strong>{details?.user?.email}</p>
             <p> <strong>Numéro de téléphone : </strong> {details?.user?.tel}</p></div>
              </div>
            
            
            </div>
            <div className="col-12 border-details m-2 p-3">
              <h5>Informations de livraison</h5>
              <div className="row">
                <div className="col-6 mt-24">
                <p className=""><strong>Nom de l'objet : </strong> {details?.name}</p>
          <p><strong>ID annonce : </strong> {details?.reference}</p>
          <p><strong>Date et heure de mise en ligne de l'annonce : </strong> {new Date(details?.createdAt).toLocaleDateString('FR-fr')}</p>
          <p><strong>Photo : </strong> <img
                src={`${process.env.REACT_APP_URL_UPLOAD}${details?.image}`}
                className=" w-25"
                alt="ANNONCE IMG"
              /></p>
                </div>
                <div className="col-6 mt-24">  <p><strong>Prix : </strong> {details?.price} euros</p>
          <p><strong>Destination : </strong> {details?.airportDestination}</p>
          <p><strong>Ville de livraison : </strong> {details?.deliveryCity}</p>
          <p><strong>Département de livraison : </strong> {details?.dept}</p>
          <p><strong>Date max souhaitée : </strong> {new Date(details?.receptionDate).toLocaleDateString('FR-fr')}</p></div>
              </div>
             
        
          </div>
          <div className="col-12 border-details m-2 p-3">
            <h5>Informations colis</h5>
            <div className="row">
              <div className="col-6 mt-24"> <p className=""><strong>Type de produit : </strong> {details?.type}</p>
          <p><strong>Nombre d'articles : </strong> {details?.nbArticle}</p>
          <p><strong> Lien du site : </strong> <a href={`${details?.siteLink}`}>{details?.siteLink}</a> </p></div>
              <div className="col-6 mt-24"> <p><strong>Taille : </strong> {details?.size}</p>
          <p><strong>Poids : </strong> {details?.weight}</p></div>
            </div>
        
         
        
          </div>
          </div>
          {/* <button onClick={() => window.history.back()} className="cta">Back</button> */}
            
          </>
        )}
      </div>
    </Layout>
  );
};

export default DetailsAnnonce;

import { useEffect, useState } from "react";
import Layout from "../layout";
import Services from "../../services/global.service";
import { useParams } from "react-router";

const DetailsAnnonce = () => {
  const params = useParams();
  const [userDetails, setUserDetails] = useState(null); // Initialize with null

  const getDetails = () => {
    Services.getOne(params.id, "user")
      .then((res) => {
        console.log('respons by id',res);
        setUserDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function getUsers(){
    console.log('here')
    /*get user with role=== role_admin**/
    Services.getAll('user/getAll',localStorage.getItem('token'))
    .then((response)=>
    {
     console.log('response all', response?.data);
     response?.data?.map((user)=>{
      if( user?._id ===params?.id){
        setUserDetails(user)
      }
     })
  //  setUsers(response?.data);
    })
    .catch((error)=>{
      console.log(error);
    });
  }
  useEffect(() => {
    console.log('params.id,',params.id,)
    getUsers();
  }, [params.id]); // Update details when params.id changes
  function supprimerCaracteresEntrePlusEtArobase(email) {
    return email.replace(/\+\d+\@/g, '@');
}
  return (
    <Layout>
      <div className="container mt-5">
        <h1>Détails utilisateur</h1>
        {userDetails && ( // Conditionally render when details is available
          <>
           
            <div className="row p-2">
            <div className="col-12 border-details m-2 p-3">
              {/*  */}
              <h5>Informations utilisateur</h5>
              <div className="row">
                <div className="col-6 mt-24"> 
                <p><strong>Cin : </strong> <img
                src={`${process.env.REACT_APP_URL_UPLOAD}${userDetails?.cinRecto}`}
                className=" w-100"
                alt="Cin IMG"
              /></p><p className=""> <strong>Prénom : </strong> {userDetails?.firstName}</p>
             <p><strong>Nom : </strong>{userDetails?.lastName}</p>
             <p><strong>Date de création compte : </strong>{new Date(userDetails?.createdAt).toLocaleDateString('FR-fr')}</p>
          
             </div>
                <div className="col-6 mt-24"> <p><strong>E-mail : </strong>{userDetails?.status==='actif'?userDetails?.email:supprimerCaracteresEntrePlusEtArobase(userDetails?.email)}</p>
             <p> <strong>Numéro de téléphone : </strong> {userDetails?.tel}</p></div>
              </div>
            
            
            </div>
            <div className="col-12 border-details m-2 p-3">
              <h5>Mentions légales</h5>
              <div className="row">
                <div className="col-6 mt-24">
                <p className=""><strong>Essentiels: </strong> {userDetails?.essentialCheck?'Oui':'Non'}</p>
          <p><strong>Performances de contenu : </strong> {userDetails?.contenuPerCheck?'Oui':'Non'}</p>
          <p><strong>Bons plans Koper: </strong>{userDetails?.bonplanCheck?'Oui':'Non'} </p>
          
                </div>
                <div className="col-6 mt-24">  <p><strong>Activités de marketing : </strong>{userDetails?.activitiesCheck?'Oui':'Non'}</p>
          <p><strong>Koper : </strong> {userDetails?.koperCheck?'Oui':'Non'}</p>
          <p><strong>Facebook : </strong> {userDetails?.facebookCheck?'Oui':'Non'}</p>
          <p><strong>Google : </strong> {userDetails?.googleCheck?'Oui':'Non'}</p>
          <p><strong>Tik-tok : </strong>{userDetails?.tiktokCheck?'Oui':'Non'}</p>
          <p><strong>LinkedIn : </strong> {userDetails?.linkedinCheck?'Oui':'Non'}</p></div>

              </div>
             
        
          </div>
        
      
          </div>
          {/* <button onClick={() => window.history.back()} className="cta">Back</button> */}
            
          </>
        )}
      </div>
    </Layout>
  );
};

export default DetailsAnnonce;

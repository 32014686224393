import axios from "axios";
const API_URL = process.env.REACT_APP_URL_API || ''

const token = localStorage.getItem('token')
console.log("api",API_URL)
const headersFiles = {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    },
  };
  const login =async (email, password) => {
    return axios
      .post(API_URL + 'auth/admin/signin', {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response) {
          console.log(response.data);
           localStorage.setItem('token', response.data.token);
           localStorage.setItem('id', response.data._id);
        }
        return response.data;
      })
      .catch((err) => {
        console.log('erreur login', err);
        throw err; // Throw the error to be caught in the promise chain
      });
  };
  
  const changePassword = (token, password) => {
    console.log(token);
    return axios
      .post(API_URL + "auth/change-password", {
        token: token,
        password: password,
      })
      .then((response) => {
       
        return response.data;
      })
      .catch((err) => { 
        console.log("erreur chnagepass", err);
        return err;
       
      });
  };
  const resetPassword = (email) => {
    return axios
      .post(API_URL + "auth/reset-password", {
        email: email,
      })
      .then((response) => {
        if (response) {
        console.log(response.data)
          
        }
        return response.data;
      })
      .catch((err) => {
        console.log("erreur login", err);
        return err;
        
      });
  };
  const getCurrentUser = () => {
    const id = localStorage.getItem("id");
    if (id) return id;
  };
  const profil =  () => {
    const userId = getCurrentUser();
    return axios
      .get(API_URL + "user/get/" + userId, headersFiles)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  };
  const PasswordChange =async (data) => {
   const user=  await profil();
   const datas={
    email:user?.email,
    currentPassword:data?.actualPassword,
    newPassword: data?.newPassword,
   }
  console.log(datas);
    return axios
      .post(API_URL + "auth/password-change", datas)
      .then((response) => {
       
        return response.data;
      })
      .catch((err) => { 
        console.log("erreur chnagepass", err);
        return err;
       
      });
  };
  const AuthService = {
    profil,
    login,
    resetPassword,
    changePassword,
    PasswordChange
   
  };
  
  export default AuthService;
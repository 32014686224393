import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
// import closeIcon from '../assets/icons/close-outline.svg';
 import './modal.scss';
const DeleteModal=({isOpenDelete,setIsOpenDelete,hideModal,Function,title})=>{
    function confirm(){
        console.log('confirmed')
        Function();
        setIsOpenDelete(false)
    }
    return ( 
    <Modal show={isOpenDelete} onHide={hideModal} className="modal-up" 
    >
    <ModalBody>
       <button className="btn btn-link float-right" onClick={()=>setIsOpenDelete(false)}>
        {/* <img src={closeIcon} alt="close icon" />   */}
        </button> 
      <h3 className='text-center'> {title}  </h3>
  <div className="row pt-20">    
   <div className="col text-end"><button className='btn-primary-ko ' onClick={confirm}>Oui</button></div> 
  <div className="col">
    <button className='btn-secondary-ko ' onClick={()=>{setIsOpenDelete(false)}} >Non</button>
    </div>

  
    </div>  
    </ModalBody>

  </Modal>)
    
}
export default DeleteModal;
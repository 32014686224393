import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck , faUserAltSlash, faTimesCircle, faTimeline, faBarsProgress, faPen, faFishFins, faTruckFast, faCalendarDays, faHandshake, faPiggyBank, faMoneyBillTrendUp, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import Services from "../services/global.service";
import Layout from "./layout";

function Dashboard() {
    const [stat, setStat]=useState();
  function getUsers() {
    console.log("here");
    /*get user with role=== role_admin**/
    Services.getAll("user/dashboardStat",localStorage.getItem('token'))
      .then((response) => {
        console.log("res", response);
        setStat(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Layout>
        <div className="col  bg-fond container-k">
          <div className="m-vh-100  p-40">
            <div className="container">
              <div className="d-flex justify-content-between">
                <h1 className="d-title">
                  Dashboard <span className="border-title"></span>
                </h1>{" "}
              </div>

              <div className="space-72"></div>
            </div>
            <div className="koper mt-3">
              <div className="row">
                <div className="col">
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title">  <FontAwesomeIcon icon={faUserCheck} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                         {stat?.usersInscrit}   Utilisateurs inscrits
                      </h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faUserAltSlash} className="icon"/></h5>
                      <h5 class="card-subtitle my-2  text-muted">
                         {stat?.usersDeleted} Comptes supprimés
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"> <FontAwesomeIcon icon={faCalendarDays} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      {stat?.scheduledAnnonce} Annonces programmées
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faHandshake} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      {stat?.progressAnnonce} Annonces en cours
                      </h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faPen} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      {stat?.createdAnnonce}  Annonces créées 
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faTruckFast} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      {stat?.completedAnnonce} Colis livrés
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faMoneyBillTrendUp} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      CA : {stat?.totalAmountWithCommission} €
                      </h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faMoneyBillTransfer} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      Cumul transaction :  {stat?.totalAmountWithoutCommission} €
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div class="card border-details card-koper">
                    <div class="card-body text-center">
                      <h5 class="card-title"><FontAwesomeIcon icon={faPiggyBank} className="icon"/></h5>
                      <h5 class="card-subtitle my-2 text-muted">
                      Cumul comission :  {stat?.totalCommission} €
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
